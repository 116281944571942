import React from 'react';
import { Box, Button, Flex, Image, Link, Spacer} from '@chakra-ui/react';
import Facebook from './assets/social-media-icons/facebook_32x32.png';
import Twitter from './assets/social-media-icons/twitter_32x32.png';
import Email from './assets/social-media-icons/email_32x32.png';

const NavBar = ({ accounts, setAccounts }) => {
    const isConnected = Boolean(accounts[0]);

    async function connectAccount() {
        console.log('Connecting account...');
        console.log('Before connection check:', isConnected);
        console.log('Window ethereum:', window.ethereum);
      
        if (window.ethereum) {
          console.log('Window ethereum is not available');
          const accounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
          });
          console.log('Accounts:', accounts);
          setAccounts(accounts);
          alert('Connected to ' + accounts[0]);
        }
      }
      
    return (
        <Flex justify='space-between' align='center' padding="30px">
            {/* left side - Social media links */}
            <Flex justify="space-around" width="40%" padding="30px">
              <Link href="https://www.facebook.com/">
                <Image src={Facebook} alt="Facebook" boxSize="42px" margin="0 15px" />
              </Link>
              <Link href="https://www.twitter.com/">
                <Image src={Twitter} alt="Twitter" boxSize="42px" margin="0 15px" />
              </Link>
              <Link href="https://www.gmail.com/">
                <Image src={Email} alt="Email" boxSize="42px" margin="0 15px" />
              </Link>
            </Flex>
            {/*Right side - sections and connect */}
            <Flex justify="space-around" align="center" width="40%" padding="30px">
              <Box margin="0 15px">About</Box>
              <Spacer /> 
              <Box margin="0 15px">Mint</Box>
              <Spacer /> 
              <Box margin="0 15px">Team</Box>
              <Spacer />

              {/* Connect */}
              {isConnected ? (
                <Box  margin="0 15px">Connected</Box>
              ) : (
                  <Button
                    backgroundColor="#d6517d" 
                    borderRadius="5px"  
                    boxShadow="0px 2px 2px 1px #0f0f0f"
                    color="#ffffff"
                    cursor="pointer"
                    fontFamily="inherit"
                    padding="15px"
                    margin="0 15px"
                    onClick={connectAccount}>Connect</Button>
              )}
            </Flex>

        </Flex>
    );
};

export default NavBar;