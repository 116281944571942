import {useState} from 'react';
import {ethers, BigNumber} from 'ethers';
import { Box, Button, Flex, Input, Text} from '@chakra-ui/react';
import './App.css'; // Import the CSS file where you define your classes

import TestCollection from './TestCollection.json';

//const TestCollectionAddress = '0x68E7d70b5d197f2b2a78ca5cA660D4378D7D0bc9'; //sepolia
const TestCollectionAddress = '0x2061692e21bf5522b66D8F0c7c01Fa5d559543Ae'; //goerli


const MainMint = ({accounts, setAccounts}) => {
    const [mintAmount, setMintAmount] = useState(1);
    const isConnected = Boolean(accounts[0]);

    async function handleMint(){
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                TestCollectionAddress,
                TestCollection.abi,
                signer
            );
            try{
                const response = await contract.mint(BigNumber.from(mintAmount), {
                    value: ethers.utils.parseEther((mintAmount * 0.000001).toString())
                });
                console.log('response: ', response);
            } catch (err) {
                console.log("error: ", err);
            };
        };
    };

    const handleDecrement = () => {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    };

    const handleIncrement = () => {
        if (mintAmount >= 3) return;
        setMintAmount(mintAmount + 1);
    };
    
    return (
      <Flex justify="center" align="center" height="100vh" paddingBottom="150px">
        <Box width="520px">
          <div>
            <Text className="heading">Test Mint</Text>
            <Text className="subheading">Really fucked up collection</Text>
          </div>
          {isConnected ? (
            <div>
              <Flex align="center" justify="center">
                <Button className="decrementButton" onClick={handleDecrement}>-</Button>
                <Input
                  readOnly
                  className="inputField"
                  type="number"
                  value={mintAmount}
                  onChange={() => {}}
                />
                <Button className="incrementButton" onClick={handleIncrement}>+</Button>
              </Flex>
              <Button className="mintButton" onClick={handleMint}>Mint Now</Button>
            </div>
          ) : (
            <Text className="notConnectedText">You must be connected to Mint</Text>
          )}
        </Box>
      </Flex>
    );    
};

export default MainMint;